import { InnerHtml, Col, Row } from '@geberit/gdds';
import Link from 'next/link';

// components
import { Image } from '../../Image/Image';
import { Headline, Formats, FontWeights } from '../../ContentElements/Headline';
import { CmsLink } from '../../Link/CmsLink';

// styles
import styles from './teaser.module.scss';

// utils
import { classNameBuilder } from '../../../utils/classNameBuilder';
import { useKolo } from '../../../utils/hooks/use-kolo';

const WRAPPER_SIZE = {
  small: [4, 3, 5],
  medium: [4, 5, 7],
};

const IMAGE_SIZE = {
  small: [4, 4, 6],
  medium: [4, 5, 6.7],
};

const CONTENT_SIZE = {
  small: [4, 4, 6],
  medium: [4, 3, 5.3],
};

function getImageObject(
  isMobile,
  imageForSmallAndMediumObjectMobile,
  imageForSmallAndMediumObject,
) {
  return isMobile && imageForSmallAndMediumObjectMobile.url
    ? imageForSmallAndMediumObjectMobile
    : imageForSmallAndMediumObject;
}

interface TeaserElementProps {
  content: string;
  size: string;
  headline: string;
  link: {
    label: string;
    url: string;
  };
  subline: string;
  isMobile: boolean;
  imageForSmallAndMediumObject: any;
  imageForSmallAndMediumObjectMobile: any;
  isCarousel: boolean;
  imageAltText: string;
  imageRef: any;
  offsetTop: number;
  animated: boolean;
  visible: boolean;
  isUppercase: boolean;
  isFirstItem: boolean;
  isLastItem: boolean;
  isLandingPage: boolean;
}

function TeaserElement({
  content,
  size,
  headline,
  link,
  subline,
  isMobile,
  imageForSmallAndMediumObject,
  imageForSmallAndMediumObjectMobile,
  isCarousel,
  imageAltText,
  imageRef = () => {},
  offsetTop = 0,
  animated = false,
  visible = true,
  isUppercase = false,
  isFirstItem = false,
  isLastItem = false,
  isLandingPage = false,
}: Readonly<TeaserElementProps>) {
  const isKolo = useKolo();

  const imageObj = getImageObject(
    isMobile,
    imageForSmallAndMediumObjectMobile,
    imageForSmallAndMediumObject,
  );

  const cmsLinkObj = {
    text: link?.label,
    target: link?.url,
    showArrow: true,
    window: '_self',
    type: 'internal_link',
  };

  // css zoom (we can't use css because transform doesn't take absolute values)
  const imageMouseOver = (e) => {
    if (animated) {
      const factor = (e.target.offsetWidth + 16) / (e.target.offsetWidth || 1);
      e.target.style.transform = `scale(${factor})`;
    }
  };

  const imageMouseOut = (e) => {
    if (animated) {
      e.target.style.transform = 'initial';
    }
  };

  const renderSubline = !isLandingPage || (isLandingPage && size !== 'small');

  return (
    <Col
      size={!isCarousel ? WRAPPER_SIZE[size] : [4, 8, 12]}
      className={classNameBuilder(
        size,
        styles[size],
        isCarousel && styles.withoutPadding,
        'teaserElement',
        animated && styles.animated,
        visible && styles.visible,
      )}
    >
      <Row
        style={(offsetTop && { marginTop: `${offsetTop}px` }) || undefined}
        className={classNameBuilder(
          styles.innerRow,
          isFirstItem && styles.firstItem,
          isLastItem && styles.lastItem,
        )}
      >
        <Col size={IMAGE_SIZE[size]}>
          <div ref={imageRef} className={styles.imageContainer}>
            <Link href={link?.url}>
              {imageObj?.url ? (
                <Image
                  data={imageObj}
                  alt={imageAltText}
                  title={imageAltText}
                  onMouseOver={imageMouseOver}
                  onMouseOut={imageMouseOut}
                />
              ) : (
                // workaround to keep the aspect ratio without image
                <svg viewBox="0 0 3 4"></svg>
              )}
            </Link>
          </div>
        </Col>
        <Col size={CONTENT_SIZE[size]} className={styles.teaserContentWrapper}>
          {(headline || subline) && (
            <Headline
              title={headline}
              subtitle={renderSubline && subline}
              titleFontWeight={FontWeights.bold}
              subtitleFontWeight={isKolo ? FontWeights.bold : undefined}
              format={Formats.h4}
              isUppercase={isUppercase}
            />
          )}
          {content && <InnerHtml as="span" content={content} />}
          {link?.label && link?.url && (
            <CmsLink
              link={cmsLinkObj}
              alignByContent="left"
              className={styles.link}
              standardFontSize={false}
            />
          )}
        </Col>
      </Row>
    </Col>
  );
}

export default TeaserElement;
